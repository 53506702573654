import Seven from "../assets/7.svg";
import "./ui-components/icnImage.css"
import "./Logout.css"
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Logout = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
  
    const handleLogout = () => {
      logout();
      navigate("/");
    };

    return (
        <div className="bottom-holder">
            <span
                onClick={() => handleLogout()
                }
            >
                <a
                    style={{
                        color: "white",
                        marginLeft: "30px",
                        marginRight: "30px",
                        marginTop: "25px",
                        fontSize: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        textDecoration: "none",
                        fontWeight: 800,
                        letterSpacing: "1px",
                    }}
                    href="/"
                >
                    Logout <img src={Seven} alt="Logout" className="icn-2" />
                </a>
            </span>
        </div>
    )
}

export default Logout