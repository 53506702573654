// ZoomAnalysis.js
import React, { useState, useEffect } from 'react';
import { SessionCard } from '../component/SessionCard';
import { SessionDetail } from '../component/SessionDetail';
import { DateRangeFilter } from '../component/DateRangeFilter';
import { RepresentativeFilter } from '../component/RepresentativeFilter';
import { RoleFilter } from '../component/RoleFilter';
import { PerformanceFilter } from '../component/PerformanceFilter';
import PaginationControl from '../component/PaginationControl';
import { ViewSelector } from '../component/viewSelector';
import ExportButton from "../assets/export.svg";
import { fetchSessionData, normalizeSessionData, normalizeSessionDataStrategist, representativeData } from '../api/zoomanalysisApi';
import { SessionDetailStrategist } from '../component/SessionDetailStrategist';
import ExportData from '../component/ExportData';
function ZoomAnalysis() {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRepresentative, setSelectedRepresentative] = useState('All Representatives');
  const [representatives, setRepresentatives] = useState(['All Representatives']);
  const [selectedRole, setSelectedRole] = useState('All');
  const [performaceScore, setPerformaceScore] = useState('All Scores');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1)
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPage, updateTempPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentView, setCurrentView] = useState('grid'); // Default to grid view
  const [colClass, setColClass] = useState("col-md-3");
  const [showExport, setShowExport] = useState(false)

  // Adjust layout for responsiveness
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 990) {
        setColClass("col-md-6 mt-1");
      } else {
        setColClass("col-md-3");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  }
  
  // Assuming startDate and endDate are in 'yyyy-mm-dd' format
  
    useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      setError(null);
      const formattedStartDate = startDate !="" ? formatDate(startDate) : "";
      const formattedEndDate = endDate !="" ? formatDate(endDate) : "";
      try {
        const data = await fetchSessionData(
          currentPage,
          itemsPerPage,
          formattedStartDate,
          formattedEndDate,
          selectedRole,
          performaceScore == "All Scores" ? "" : performaceScore,
          selectedRepresentative
        );

       

        const normalizedData = normalizeSessionData(data.Analysis, "Specialist");
      
        const normalizedStrategistData = normalizeSessionDataStrategist(data.Analysis, "Strategist");
       setTotalPages(data.Total_Pages)
        setSessions([...normalizedData, ...normalizedStrategistData]);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [
    currentPage,
    itemsPerPage,
    startDate,
    endDate,
    selectedRole,
    performaceScore,
    selectedRepresentative,
    
  ]);


  useEffect(() => {
    const fetchRepresentatives = async () => {
        try {
            const data = await representativeData(selectedRole);
            const allReps = ['All Representatives', ...data];

            // If the current representative is not in the new list, reset it
            if (!allReps.includes(selectedRepresentative)) {
                setSelectedRepresentative('All Representatives');
            }

            setRepresentatives(allReps);
        } catch (err) {
            console.error('Error fetching representatives:', err);
            setError('Failed to fetch representatives.');
        }
    };

    fetchRepresentatives();
}, [selectedRole]); // Only depends on `selectedRole`

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div className="container-fluid py-4">
      {!selectedSession ? (
        <>
          <div className="row g-3">
            <div className={`${colClass}`}>
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={(date) => {
                  setStartDate(date);
                  setCurrentPage(1);
                }}
                onEndDateChange={(date) => {
                  setEndDate(date);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className={`${colClass}`}>
              <RoleFilter
                roles={["All", "Strategist", "Specialist"]}
                selectedRole={selectedRole}
                onRoleChange={(role) => {
                  setSelectedRole(role);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className={`${colClass}`}>
              <RepresentativeFilter
                representatives={representatives}
                selectedRepresentative={selectedRepresentative}
                onRepresentativeChange={(rep) => {
                  setSelectedRepresentative(rep);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className={`${colClass}`}>
              <PerformanceFilter
                ratings={[
                  "All Scores",
                  "From 4 to 5",
                  "From 3 to 4",
                  "Over 3",
                  "Under 3",
                ]}
                performaceScore={performaceScore}
                onPerformaceScoreChange={(rate) => {
                  setPerformaceScore(rate);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          {loading ? (
            <div className="text-center">Loading...</div>
          ) : error ? (
            <div className="alert alert-danger">Error: {error}</div>
          ) : (
            <>
              <div className="mb-3" style={{ overflow: "hidden" }}>
                <div className="d-flex" style={{ float: "right", gap:"10px"}} >
                  <button  className="px-3 py-2 mt-1 text-white rounded d-flex align-items-center" 
style={{ backgroundColor: '#1f9ad6',border:"none",fontSize: "15px", fontWeight: 500, color: "white" }} 
                onClick={()=> setShowExport(prev => !prev)}
                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" style={{marginRight:"8px"}} viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg>

                Export Data
                </button>
                  {/* <ViewSelector
                    currentView={currentView}
                    onViewChange={setCurrentView}
                    
                  /> */}
                </div>
              </div>
              {showExport && <div className="" style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background
      zIndex: 1000, // Bring it above other elements
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}> <ExportData setShowExport={setShowExport}/> </div>}
              <div className={`row g-3 ${currentView === "list" ? "flex-column" : ""}`}>
                {sessions.map((session) => (
                  <div
                    className={currentView === "grid" ? "col-md-6" : "col-12"}
                    key={session.id}
                  >
                    <SessionCard
                      session={session}
                      onClick={setSelectedSession}
                      viewType={currentView}
                    />
                  </div>
                ))}
              </div>
              <div className='mt-3'>
              <PaginationControl
                currentPage={currentPage}
                totalPages={totalPages}
                tempPage={tempPage}
                setCurrentPage={setCurrentPage}
                updateTempPage={updateTempPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
              </div>
            </>
          )}
        </>
      ) : sessions.find((s) => s?.id === selectedSession)?.representative.role ==
        "Specialist" ? (
        <SessionDetail
          session={sessions.find((s) => s?.id == selectedSession)}
          onBack={() => setSelectedSession(null)}
        />
      ) : (
        <SessionDetailStrategist
          session={sessions.find((s) => s?.id == selectedSession)}
          onBack={() => setSelectedSession(null)}
        />
      )}
    </div>
  );
}

export default ZoomAnalysis;

// import React, { useState, useEffect, useMemo } from 'react';
// import { SessionCard } from '../component/SessionCard';
// import { SessionDetail } from '../component/SessionDetail';
// import { DateRangeFilter } from '../component/DateRangeFilter';
// import { RepresentativeFilter } from '../component/RepresentativeFilter';
// import { RoleFilter } from '../component/RoleFilter';
// import ZoomPagination from '../component/ZoomPagination';
// import { fetchSessionData, fetchSessionDataStrategist, normalizeSessionData, normalizeSessionDataStrategist } from '../api/zoomanalysisApi';
// import { PerformanceFilter } from '../component/PerformanceFilter';
// import { SessionTypeFilter } from '../component/SessionTypeFilter';
// import { ScorecardFilters } from '../component/ScoreCardFilters';
// import PerformanceMetrics from '../component/PerformanceMetrics';
// import { SessionDetailStrategist } from '../component/SessionDetailStrategist';
// import { ViewSelector } from '../component/viewSelector';
// import PaginationControl from '../component/PaginationControl';

// function ZoomAnalysis() {
//   const [sessions, setSessions] = useState([]);
//   const [selectedSession, setSelectedSession] = useState(null);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [selectedRepresentative, setSelectedRepresentative] = useState('All Representatives');
//   const [selectedRole, setSelectedRole] = useState('All');
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [sessionType, setSessionType] = useState('All Sessions')
//   const [performaceScore, setPerformaceScore] = useState('All Scores')
//   // Pagination state
//   // const [currentPage, setCurrentPage] = useState(1);
//   // const itemsPerPage = 10;
//   const [activeTab, setActiveTab] = useState("sessions");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [tempPage, updateTempPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
  
// const [currentView, setCurrentView] = useState('grid'); // Default to grid view

//   // Fetch data from the API
//   useEffect(() => {
//     const fetchSessions = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const data = await fetchSessionData(currentPage,itemsPerPage);
//         const strategistData = await fetchSessionDataStrategist(currentPage,itemsPerPage); 
//         const normalizedData = normalizeSessionData(data,"Specialist");
//         const normalizedDataSpecialist = normalizeSessionDataStrategist(strategistData,"Strategist");

//         setSessions([...normalizedData,...normalizedDataSpecialist]);
//         console.log([...normalizedData,...normalizedDataSpecialist])

//       } catch (err) {
//         console.error('Error fetching data:', err);
//         setError('Failed to fetch data. Please try again later.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchSessions();
//   }, [currentPage,itemsPerPage]);

//   // Filter sessions based on filters
//   const filteredSessions = useMemo(() => {
//     return sessions.filter((session) => {
//       const sessionDate = new Date(session?.date);
//       const matchesDate =
//         (!startDate || sessionDate >= new Date(startDate)) &&
//         (!endDate || sessionDate <= new Date(endDate));

//       const matchesRepresentative =
//         selectedRepresentative === 'All Representatives' ||
//         (session?.representative.name &&
//           session?.representative.name === selectedRepresentative);

//       const matchesRole =
//         selectedRole === 'All' ||
//         (session?.representative.role &&
//           session?.representative.role === selectedRole);

//           const matchesRating = (() => {
//             if (performaceScore === 'All Scores') return true;
//             if (performaceScore === 'From 4 to 5') return session.rating <= 5 && session.rating >= 4;
//             if (performaceScore === 'From 3 to 4') return session.rating <= 4 && session.rating >= 3;
//             if (performaceScore === 'Over 3') return session.rating >= 3;
//             if (performaceScore === 'Under 3') return session.rating < 3;
//             return true;
//           })();

//       // const matchesSessionType =
//       // sessionType === 'All Sessions' ||
//       // (session.rating &&
//       //   session.rating === selectedRepresentative);

//       return matchesDate && matchesRepresentative && matchesRole && matchesRating;
//     }).sort((a, b) => new Date(b.date) - new Date(a.date)); 

//   }, [sessions, startDate, endDate, selectedRepresentative, selectedRole, performaceScore]);

//   const totalItems = filteredSessions.length;
//   const totalPages = Math.ceil(totalItems / itemsPerPage);

//   const currentSessions = useMemo(() => {
//     const indexOfLastSession = currentPage * itemsPerPage;
//     const indexOfFirstSession = indexOfLastSession - itemsPerPage;
//     return filteredSessions.slice(indexOfFirstSession, indexOfLastSession);
//   }, [filteredSessions, currentPage, itemsPerPage]);

//   const currentSession = sessions.find((s) => s?.id === selectedSession);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const representatives = useMemo(() => {
//     const repsSet = new Set(sessions.map((s) => s?.representative.name).filter((name) => name));
//     const reps = Array.from(repsSet);
//     return ['All Representatives', ...reps];
//   }, [sessions]);


//   const ratings = useMemo(() => {
//     return [
//       'All Scores',
//       'From 4 to 5',
//       'From 3 to 4',
//       'Over 3',
//       'Under 3',
//     ];
//   }, []);


//   const roles = ['All', 'Strategist', 'Specialist'];

//   const filteredRepresentatives = useMemo(() => {
//     const repsSet = new Set(
//       sessions
//         .filter(
//           (session) =>
//             selectedRole === 'All' || session?.representative.role === selectedRole
//         )
//         .map((session) => session?.representative.name)
//         .filter((name) => name)
//     );
  
//     return ['All Representatives', ...Array.from(repsSet)];
//   }, [sessions, selectedRole]);

//   const filteredRoles = useMemo(() => {
//     const repsSet = new Set(
//       sessions
//         .filter(
//           (session) =>
//             selectedRepresentative === 'All Representatives' || session?.representative.name === selectedRepresentative
//         )
//         .map((session) => session?.representative.role)
//         .filter((role) => role)
//     );
  
//     return ['All', ...Array.from(repsSet)];
//   }, [sessions, selectedRepresentative]);

//   const [colClass, setColClass] = useState("col-md-3");
          
//             useEffect(() => {
//               const handleResize = () => {
//                 if (window.innerWidth < 990) {
//                   setColClass("col-md-6 mt-1");
//                 } else {
//                   setColClass("col-md-3");
//                 }
//               };
          
//               window.addEventListener("resize", handleResize);
//               handleResize(); // Trigger initially
          
//               return () => {
//                 window.removeEventListener("resize", handleResize);
//               };
//             }, []);
//   return (
//     <div className="container-fluid py-4">
//       {!selectedSession ? (
//         <>
//           {/* <h1 onClick={(e) => setActiveTab("sessions")}> Sessions</h1>
//           <h1 onClick={(e) => setActiveTab("scorecard")}> Scorecard</h1> */}

//           {activeTab === "sessions" ? (
//             <>
//               <div className="row g-3">
//                 <div className={`${colClass}`}>
//                   <DateRangeFilter
//                     startDate={startDate}
//                     endDate={endDate}
//                     onStartDateChange={(date) => {
//                       setStartDate(date);
//                       setCurrentPage(1);
//                     }}
//                     onEndDateChange={(date) => {
//                       setEndDate(date);
//                       setCurrentPage(1);
//                     }}
//                   />
//                 </div>
//                 <div className={`${colClass}`}>
//                   <RoleFilter
//                     roles={filteredRoles}
//                     selectedRole={selectedRole}
//                     onRoleChange={(role) => {
//                       setSelectedRole(role);
//                       setCurrentPage(1);
//                     }}
//                   />
//                 </div>
//                 <div className={`${colClass}`}>
//                   <RepresentativeFilter
//                     representatives={filteredRepresentatives}
//                     selectedRepresentative={selectedRepresentative}
//                     onRepresentativeChange={(rep) => {
//                       setSelectedRepresentative(rep);
//                       setCurrentPage(1);
//                     }}
//                   />
//                 </div>
             
//                 <div className={`${colClass}`}>
//                   <PerformanceFilter
//                     ratings={ratings}
//                     performaceScore={performaceScore}
//                     onPerformaceScoreChange={(rate) => {
//                       setPerformaceScore(rate);
//                       setCurrentPage(1);
//                     }}
//                   />
//                 </div>
//                 {/* <div className="col-md-3">
//                   <SessionTypeFilter
//                   />
//                 </div> */}
//               </div>

//               {loading ? (
//                 <div className="text-center">Loading...</div>
//               ) : error ? (
//                 <div className="alert alert-danger">Error: {error}</div>
//               ) : (
//                 <>
   
//                <div className="mb-3" style={{ overflow: 'hidden' }}>
               
//   <div style={{ float: 'right' }}>
//     <ViewSelector
//       currentView={currentView}
//       onViewChange={setCurrentView}
//     />
//   </div>
// </div>


//     <div className={`row g-3 ${currentView === 'list' ? 'flex-column' : ''}`}>
//       {currentSessions.map((session) => (
//         <div className={currentView === 'grid' ? 'col-md-6' : 'col-12'} key={session.id}>
//           <SessionCard
//   session={session}
//   onClick={setSelectedSession}
//   viewType={currentView}
// />

//         </div>
//       ))}
//       <div>

// <PaginationControl
// currentPage={currentPage}
// totalPages={totalPages}
// tempPage={tempPage}
// setCurrentPage={setCurrentPage}
// updateTempPage={updateTempPage}
// itemsPerPage={itemsPerPage}
// setItemsPerPage={setItemsPerPage}
// />
// </div>
//     </div>
  
//     {/* Use Here */}
//                   {/* <ZoomPagination
//                     totalItems={totalItems}
//                     itemsPerPage={itemsPerPage}
//                     onPageChange={handlePageChange}
//                     currentPage={currentPage}
//                     totalPages={totalPages}
//                   /> */}
//                 </>
//               )}
//             </>
//           ) : (
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col-12">
//                   <ScorecardFilters
//                     fromDate={startDate}
//                     toDate={endDate}
//                     selectedRepresentative={representatives}
//                     selectedRole={selectedRole}
//                     selectedRepresentatives={selectedRepresentative}
//                     onStartDateChange={(date) => {
//                       setStartDate(date);
//                       setCurrentPage(1);
//                     }}
//                     onToDateChange={(date) => {
//                       setEndDate(date + 1);
//                       setCurrentPage(1);
//                     }}
//                     onRepresentativeChange={(rep) => {
//                       setSelectedRepresentative(rep);
//                       setCurrentPage(1);
//                     }}
//                     onRoleChange={(role) => {
//                       setSelectedRole(role);
//                       setCurrentPage(1);
//                     }}
//                     onRepresentativesChange={(rep) => {
//                       setSelectedRepresentative(rep);
//                       setCurrentPage(1);
//                     }}
//                     representatives={representatives}
//                     roles={roles}
//                   />
//                   {/* <PerformanceMetrics /> */}
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       ) : currentSession && currentSession?.representative.role === "Specialist" ? (
//         <SessionDetail
//           session={currentSession}
//           onBack={() => setSelectedSession(null)}
//         />
//       ) : (<SessionDetailStrategist session={currentSession} onBack={() => setSelectedSession(null)}/>)}
//     </div>
//   );
// }

// export default ZoomAnalysis;
