import { useLocation } from "react-router-dom";
import Profile from "../../assets/profile.svg";
import styles from "./TopNav.module.css"; // Import CSS module

const TopNav = ({ toggleMenu }) => {
    const location = useLocation();

    return (
        <div className={`row ${styles.customTopNav}`}>
            <div className={`col-md-12 ${styles.topNavWithName}`}>
                <p className={`page-heading ${styles.pageHeading}`}>
                    {location.pathname === "/dashboard/" ||
                        location.pathname === "/dashboard"
                        ? "Analytics"
                        : location.pathname === "/dashboard/users"
                            ? "Users"
                            : location.pathname === "/dashboard/inbox"
                                ? "Inbox"
                                : location.pathname === "/dashboard/leads"
                                    ? "Leads"
                                    : location.pathname === "/dashboard/zoom-analysis"
                                        ? "Zoom Analysis"
                                         : location.pathname === "/dashboard/sessionsummary"
                                        ? "Summary Analysis"
                                        : location.pathname === "/dashboard/settings"
                                            ? "Settings"
                                            : ""}
                </p>
                <img
                    src={Profile}
                    alt="profile"
                    className={styles.profileIconNav}
                />
                <button className={styles.collapsedNav} onClick={toggleMenu}>
                    <div
                        className={styles.line}
                    ></div>
                    <div
                        className={styles.line}
                    ></div>
                    <div
                        className={styles.line}
                    ></div>
                </button>
            </div>
        </div>
    )
}

export default TopNav