// ZoomAnalysisApi.js

import axios from 'axios';

// Function to fetch session data
export const fetchSessionData = async (pageno,pagesize,from_date,to_date,role,score,representative_name) => {
    try {
        const response = await axios.get(`https://zoom.kuru.ai/filter_data?page=${pageno}&page_size=${pagesize}&from_date=${from_date}&to_date=${to_date}&role=${role}&representative_name=${representative_name}&score=${score}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to fetch data. Please try again later.');
    }
};

export const representativeData = async (role) => {
    try {
        const response = await axios.get(`https://zoom.kuru.ai/fetch_representative_names`);
        const lowerCaseRole = role.toLowerCase(); // Convert role to lowercase

        if (response.data) {
            if (role == "All") {
                // Combine both arrays into one
                const combinedArray = [
                    ...(response.data?.specialist || []),
                    ...(response.data?.strategist || [])
                ];
                return combinedArray;
            } else if (response.data[lowerCaseRole]) {
                return response.data[lowerCaseRole]; // Return the filtered data based on role
            } else {
                throw new Error(`Role "${lowerCaseRole}" not found.`);
            }
             
    }
 } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to fetch data. Please try again later.');
    }
};

// Helper function to parse flag strings into structured objects
export const parseFlags = (flagString) => {
    if (!flagString) return [];
    const flagEntries = flagString.split('\n\n'); // Split by double newline to separate each flag
    const flags = flagEntries.map((entry) => {
        const lines = entry.split('\n').map((line) => line.trim());
        const flag = {};
        lines.forEach((line) => {
            if (line.startsWith('- Category:')) {
                flag.category = line.replace('- Category:', '').trim();
            } else if (line.startsWith('Text:')) {
                flag.text = line.replace('Text:', '').trim();
            } else if (line.startsWith('Context:')) {
                flag.context = line.replace('Context:', '').trim();
            }
        });
        return flag;
    });
    return flags;
};

// Function to normalize session data
export const normalizeSessionData = (data,role) => {
    return data
    .filter((session) => session && session.introduction)
    .map((session) => {
        // Prepare participants list
        const participantsArray = session.participants
            ? session.participants.split(',').map((p) => p.trim())
            : [];

        // Remove duplicates
        const uniqueParticipants = Array.from(new Set(participantsArray));

        // For title, remove strategist
        const participantsWithoutStrategist = uniqueParticipants.filter(
            (p) => p.toLowerCase() !== session.strategist.toLowerCase()
        );

        // Capitalize participant names for title
        const capitalizedParticipants = participantsWithoutStrategist.map((name) =>
            name
                .split(' ')
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                .join(' ')
        );

        // Create session title
        const title =
            capitalizedParticipants.length > 0
                ? `${capitalizedParticipants.join(', ')} Property Session`
                : 'Property Session';


        const overallAssessment = session.overall_assessment.split("\n").filter(line => line.trim() !== "").reduce((acc, line) => {
            const [key, ...value] = line.split(":");
            const valueString = value.join(":").trim();
            
            if (key.trim() === "Rating") {
                // Extract the numerator from the rating
                acc[key.trim()] = valueString.split("/")[0].trim();
            } else {
                acc[key.trim()] = valueString;
            }
        
            return acc;
        }, {});

        const parseTextToObject = (text) => {
            if (!text) return {}
            return text.split("\n")
                .filter(line => line.trim() !== "")  // Remove empty lines
                .reduce((acc, line) => {
                    const [key, ...value] = line.split(":");
                    const keyTrimmed = key.trim();
                    let valueString = value.join(":").trim();
        
                    if (keyTrimmed === "Rating") {
                        // Extract the numerator from the rating (if it contains a fraction)
                        valueString = valueString == "No rating" ? "0" : valueString.split("/")[0].trim();
                        acc[keyTrimmed] = valueString;   
                                        } else {
                        // Handle repeated keys by appending _2, _3, etc.
                        if (acc[keyTrimmed]) {
                            let suffix = 2;
                            while (acc[`${keyTrimmed}_${suffix}`]) {
                                suffix++;
                            }
                            acc[`${keyTrimmed}_${suffix}`] = valueString;
                        } else {
                            acc[keyTrimmed] = valueString;
                        }
                    }
        
                    return acc;
                }, {});
        };
        
        


        const introduction = parseTextToObject(session.introduction)
        const cashFlowTour = parseTextToObject(session.cash_flow_tour)
        const closingAndFollowUp = parseTextToObject(session.closing_and_follow_up)
        const FreedomMethodPresentation = parseTextToObject(session.freedom_method_presentation)
        const memberUnderstandingAndJourney = parseTextToObject(session.member_understanding_and_journey)
        const preparation = parseTextToObject(session.preparation)
        const theGap = parseTextToObject(session.the_gap)
        const unpack = parseTextToObject(session.unpack)
        return {
            
            id: `specialist-${session.id.toString()}`,
            date: session.session_date || "Unknown",
            representative: {
                name: session.strategist || 'Unknown',
                email: session.strategist_email,
                role: role,
            },
            overallAssessment:overallAssessment,
            introduction:introduction,
            cashFlowTour:cashFlowTour,
            closingAndFollowUp:closingAndFollowUp,
            FreedomMethodPresentation:FreedomMethodPresentation,
            memberUnderstandingAndJourney:memberUnderstandingAndJourney,
            preparation:preparation,
            theGap:theGap,
            unpack:unpack,
            // done: ,
            // notDone: ,
            participants: uniqueParticipants.map((name) => ({ name })), // Include strategist
            duration: session.session_duration,
            rating: overallAssessment.Rating ? overallAssessment.Rating : null, // Convert to out of 10
            ratingJustification: session.rating_justification,
            leadIntentScore: session.lead_intent_score,
            leadIntentJustification: session.lead_intent_score_justification,
            summary: session.summary_of_session,
            sessionStrengths: session.strengths_of_the_session
                ? session.strengths_of_the_session.split('\n')
                : [],
            sessionWeaknesses: session.weaknesses_of_the_session
                ? session.weaknesses_of_the_session.split('\n')
                : [],
            recommendations: session.recommendation_for_improvement
                ? session.recommendation_for_improvement.split('\n')
                : [],
            conclusion: session.conclusion,
            flags: {
                red: parseFlags(session.red_flags),
                orange: parseFlags(session.orange_flags),
                yellow: parseFlags(session.yellow_flags),
                green: parseFlags(session.green_flags),
            },
            topics: [], // Add if you have topics data
            videoLink: session.zoom_link,
            title: session.title, // Updated title
        };
    });
};

export const normalizeSessionDataStrategist = (data, role) => {
    // const parseTextToObject = (text) => {
    //     if (!text) return {}; // Safeguard against undefined or null text
    //     return text
    //         .split("\n")
    //         .filter((line) => line.trim() !== "") // Remove empty lines
    //         .reduce((acc, line) => {
    //             const [key, ...value] = line.split(":");
    //             const valueString = value.join(":").trim();
    //             if (key.trim() === "Rating") {
    //                 acc[key.trim()] = valueString.split("/")[0].trim();
    //             } else {
    //                 acc[key.trim()] = valueString;
    //             }
    //             return acc;
    //         }, {});
    // };
    const parseTextToObject = (text) => {
        if (!text) return {}
        return text.split("\n")
            .filter(line => line.trim() !== "")  // Remove empty lines
            .reduce((acc, line) => {
                const [key, ...value] = line.split(":");
                const keyTrimmed = key.trim();
                let valueString = value.join(":").trim();
    
                if (keyTrimmed === "Rating") {
                    // Extract the numerator from the rating (if it contains a fraction)
                    valueString = valueString == "No rating" ? "0" : valueString.split("/")[0].trim();
                    acc[keyTrimmed] = valueString;        
                        } else {
                    // Handle repeated keys by appending _2, _3, etc.
                    if (acc[keyTrimmed]) {
                        let suffix = 2;
                        while (acc[`${keyTrimmed}_${suffix}`]) {
                            suffix++;
                        }
                        acc[`${keyTrimmed}_${suffix}`] = valueString;
                    } else {
                        acc[keyTrimmed] = valueString;
                    }
                }
    
                return acc;
            }, {});
    };
    
    
    const parseFlags = (flags) => {
        if (!flags) return [];
        return flags.split(",").map((flag) => flag.trim());
    };

    return data
    .filter((session) => session && session.rapport_building)
    .map((session) => {
        const participantsArray = session.participants
            ? session.participants.split(",").map((p) => p.trim())
            : [];

        const uniqueParticipants = Array.from(new Set(participantsArray));
        const participantsWithoutStrategist = uniqueParticipants.filter(
            (p) => session.strategist && p.toLowerCase() !== session.strategist.toLowerCase()
        );

        const capitalizedParticipants = participantsWithoutStrategist.map((name) =>
            name
                .split(" ")
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                .join(" ")
        );

        const title =
            capitalizedParticipants.length > 0
                ? `${capitalizedParticipants.join(", ")} Property Session`
                : "Property Session";


                const qualifyingAppointment = parseTextToObject(session.appointment_qualification);
const rapportBuild = parseTextToObject(session.rapport_building);
const askForReferral = parseTextToObject(session.ask_for_referral);
const cashFlow = parseTextToObject(session.cashflow_sheet);
const unpackDiscovery = parseTextToObject(session.discovery_questions);
const finalRecap = parseTextToObject(session.final_recap);
const financeAssessment1 = parseTextToObject(session.finance_assessment_page1);
const financeAssessment2 = parseTextToObject(session.finance_assessment_page2);
const freedomCriteria = parseTextToObject(session.freedom_criteria);
const freedomKnowledge = parseTextToObject(session.knowledge_check);
const lazyEquity = parseTextToObject(session.lazy_equity_smsf);
const personalStory = parseTextToObject(session.personal_story);
const recapDecision = parseTextToObject(session.recap_decision);
const securePropertySession = parseTextToObject(session.secure_property_session);
const setAnAgenda = parseTextToObject(session.set_agenda);
const setTheToneEarly = parseTextToObject(session.set_tone_early);
const ultimateProperty = parseTextToObject(session.ultimate_property_planner);
const expectations_decision = parseTextToObject(session.expectations_decision);

let totalScore = 0;
let count = 0;

const sections = [
rapportBuild.Rating,
qualifyingAppointment.Rating,
setTheToneEarly.Rating,
setAnAgenda.Rating,
unpackDiscovery.Rating,
freedomKnowledge.Rating,
personalStory.Rating,
financeAssessment1.Rating,
financeAssessment2.Rating,
lazyEquity.Rating,
cashFlow.Rating,
ultimateProperty.Rating,
freedomCriteria.Rating,
recapDecision.Rating,
securePropertySession.Rating,
askForReferral.Rating,
expectations_decision.Rating,
finalRecap.Rating
];

sections.forEach(score => {
    if (score && !isNaN(Number(score))) { // Validate the score
        totalScore += Number(score);
        count++;
    }
});

const averageScore = totalScore / count;

        return {
            ...session,
            id: `strategist-${session.id?.toString() || "unknown"}`,
            date: session.session_date || "Unknown",
            representative: {
                name: session.strategist || "Unknown",
                email: session.strategist_email || "",
                role: role,
            },
            qualifyingAppointment: parseTextToObject(session.appointment_qualification),
            rapportBuild: parseTextToObject(session.rapport_building),
            askForReferral: parseTextToObject(session.ask_for_referral),
            cashFlow: parseTextToObject(session.cashflow_sheet),
            unpackDiscovery: parseTextToObject(session.discovery_questions),
            sessionExpectations: parseTextToObject(session.session_expectations),
            finalRecap: parseTextToObject(session.final_recap),
            financeAssessment1: parseTextToObject(session.finance_assessment_page1),
            financeAssessment2: parseTextToObject(session.finance_assessment_page2),
            freedomCriteria: parseTextToObject(session.freedom_criteria),
            freedomKnowledge: parseTextToObject(session.knowledge_check),
            lazyEquity: parseTextToObject(session.lazy_equity_smsf),
            personalStory: parseTextToObject(session.personal_story),
            recapDecision: parseTextToObject(session.recap_decision),
            securePropertySession: parseTextToObject(session.secure_property_session),
            setAnAgenda: parseTextToObject(session.set_agenda),
            setTheToneEarly: parseTextToObject(session.set_tone_early),
            ultimateProperty: parseTextToObject(session.ultimate_property_planner),
            expectations_decision: parseTextToObject(session.expectations_decision),
            participants: uniqueParticipants.map((name) => ({ name })),
            duration: session.session_duration || 0,
            rating: averageScore.toFixed(1),
            ratingJustification: session.rating_justification || "",
            leadIntentScore: session.lead_intent_score || 0,
            leadIntentJustification: session.lead_intent_score_justification || "",
            summary: session.summary_of_session || "",
            sessionStrengths: session.strengths_of_the_session
                ? session.strengths_of_the_session.split("\n")
                : [],
            sessionWeaknesses: session.weaknesses_of_the_session
                ? session.weaknesses_of_the_session.split("\n")
                : [],
            recommendations: session.recommendation_for_improvement
                ? session.recommendation_for_improvement.split("\n")
                : [],
            conclusion: session.conclusion || "",
            flags: {
                red: parseFlags(session.red_flags),
                orange: parseFlags(session.orange_flags),
                yellow: parseFlags(session.yellow_flags),
                green: parseFlags(session.green_flags),
            },
            topics: [], // Add topics data if available
            videoLink: session.zoom_link || "",
            title: session.title,
        };
    });
};
