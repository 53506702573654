import React, { useEffect, useState } from 'react';
import { Star, ChevronLeft, ChevronRight, Download, Clock } from 'lucide-react';
import "./SessionCards.css"
import * as XLSX from "xlsx";

// Pagination Component
const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1.5rem', gap: '0.5rem' }}>
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      style={{ padding: '0.25rem', borderRadius: '0.25rem', background: currentPage === 1 ? '#f9f9f9' : 'white', cursor: currentPage === 1 ? 'not-allowed' : 'pointer' , outline:"none", border:"none"}}
    >
      <ChevronLeft style={{ height: '1.25rem', width: '1.25rem' }} />
    </button>
    <span style={{ fontSize: '0.875rem', color: '#4B5563' }}>
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      style={{ padding: '0.25rem', borderRadius: '0.25rem', background: currentPage === totalPages ? '#f9f9f9' : 'white', cursor: currentPage === totalPages ? 'not-allowed' : 'pointer', outline:"none", border:"none" }}
    >
      <ChevronRight style={{ height: '1.25rem', width: '1.25rem' }} />
    </button>
  </div>
);

// Criterion Component
const Criterion = ({ name, score, isTime = false }) => (
  <div style={{"display":"flex","paddingTop":"0.375rem","paddingBottom":"0.375rem","justifyContent":"space-between","alignItems":"center"}}>
    <span style={{ fontSize: '0.875rem', color: '#374151' }}>{name}</span>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isTime ? (
        <Clock style={{ height: '0.875rem', width: '0.875rem', color: '#9CA3AF', marginRight: '0.25rem' }} />
      ) : (
        <Star style={{ height: '0.875rem', width: '0.875rem', color: '#FFD700', marginRight: '0.25rem' }} />
      )}
      <span style={{ fontSize: '0.875rem', fontWeight: '500', color: '#111827' }}>{isTime ? score : score.toFixed(1)}</span>
    </div>
  </div>
);

// ProfileAvatar Component
const ProfileAvatar = ({ name }) => {
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase();

  return (
    <div className="d-flex align-items-center">

    <div 
    className="rounded-circle p-2 me-3 d-flex align-items-center justify-content-center"
    style={{ width: '3rem', height: '3rem', background: '#1f9ad6', color: 'white', fontWeight: '500', fontSize: '1.125rem' }}>
      {initials}
    </div>
    </div>
  );
};

// SpecialistCard Component
const SpecialistCard = ({ session }) => {
  const criteria = [
    { name: 'Introduction', score: session.Criteria_Averages.introduction },
    { name: 'Cash Flow Tour', score: session.Criteria_Averages.cash_flow_tour },
    { name: 'Closing & Follow Up', score: session.Criteria_Averages.closing_and_follow_up },
    { name: 'Freedom Method', score: session.Criteria_Averages.freedom_method_presentation },
    { name: 'Member Understanding', score: session.Criteria_Averages.member_understanding_and_journey },
    { name: 'Preparation', score: session.Criteria_Averages.preparation },
    { name: 'The Gap', score: session.Criteria_Averages.the_gap },
    { name: 'Unpack', score: session.Criteria_Averages.unpack },
    { name: 'Avg Duration', score: session.Average_duration, isTime: true }
  ];

  return (
    <div style={{ "padding":"1rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","transitionProperty":"box-shadow","transitionTimingFunction":"cubic-bezier(0.4, 0, 0.2, 1)","transitionDuration":"300ms","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)" }}
      onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)'}
      onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.1)'}>
      <div style={{"display":"flex","marginBottom":"1rem","alignItems":"flex-start"}}>
        <ProfileAvatar name={session.name} />
        <div>
          <div style={{"fontWeight":500,"color":"#111827"}}>{session.name}</div>
          <div style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563","overflow":"hidden","textOverflow":"ellipsis","maxWidth":"170px"}}>{session.email}</div>
        </div>
      </div>

      <div className='criteriondivgap'>
        {criteria.map((criterion, index) => (
          <Criterion 
            key={index} 
            name={criterion.name} 
            score={criterion.score}
            isTime={criterion.isTime}
          />
        ))}
      </div>

      <div style={{ marginTop: '1rem', paddingTop: '1rem', borderTop: '1px solid #E5E7EB' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: '0.875rem', fontWeight: '500', color: '#374151' }}>Overall Score</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Star style={{ height: '1rem', width: '1rem', color: '#FFD700', marginRight: '0.25rem' }} />
            <span style={{ fontSize: '1.125rem', fontWeight: '600', color: '#1f9ad6' }}>
              {session.Overall_Average.toFixed(1)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// StrategistCard Component
const StrategistCard = ({ session }) => {
  const criteria = [
    { name: 'Rapport Build', score: session.Criteria_Averages.rapport_building },
    { name: 'Qualifying Appointment', score: session.Criteria_Averages.appointment_qualification },
    { name: 'Set Tone Early', score: session.Criteria_Averages.set_tone_early },
    { name: 'Set Agenda', score: session.Criteria_Averages.set_agenda },
    { name: 'UNPACK - Discovery', score: session.Criteria_Averages.discovery_questions },
    { name: 'Freedom Knowledge', score: session.Criteria_Averages.knowledge_check },
    { name: 'Personal Story', score: session.Criteria_Averages.personal_story },
    { name: 'Finance Assessment 1', score: session.Criteria_Averages.finance_assessment_page1 },
    { name: 'Finance Assessment 2', score: session.Criteria_Averages.finance_assessment_page2 },
    { name: 'Lazy Equity', score: session.Criteria_Averages.lazy_equity_smsf },
    { name: 'Cashflow Sheet', score: session.Criteria_Averages.cashflow_sheet },
    { name: 'Property Planner', score: session.Criteria_Averages.ultimate_property_planner },
    { name: 'Freedom Criteria', score: session.Criteria_Averages.freedom_criteria },
    { name: 'Member Decision', score: session.Criteria_Averages.recap_decision },
    { name: 'Session Time', score: session.Criteria_Averages.secure_property_session },
    { name: 'Ask for Referral', score: session.Criteria_Averages.ask_for_referral },
    { name: 'Expectation', score: session.Criteria_Averages.expectations_decision },
    { name: 'Final Recap', score: session.Criteria_Averages.final_recap },
    { name: 'Avg Duration', score: session.Average_duration, isTime: true }
  ];

  return (
    <div style={{ "padding":"1rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","transitionProperty":"box-shadow","transitionTimingFunction":"cubic-bezier(0.4, 0, 0.2, 1)","transitionDuration":"300ms","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)" }}
      onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)'}
      onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.1)'}>
      <div style={{"display":"flex","marginBottom":"1rem","alignItems":"flex-start"}}>
        <ProfileAvatar name={session.name} />
        <div>
          <div style={{"fontWeight":500,"color":"#111827"}}>{session.name}</div>
          <div style={{"fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563","overflow":"hidden","textOverflow":"ellipsis","maxWidth":"170px"}}>{session.email}</div>
        </div>
      </div>

      <div className='criteriondivgap'>
        {criteria.map((criterion, index) => (
          <Criterion 
            key={index} 
            name={criterion.name} 
            score={criterion.score}
            isTime={criterion.isTime}
          />
        ))}
      </div>

      <div style={{ marginTop: '1rem', paddingTop: '1rem', borderTop: '1px solid #E5E7EB' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: '0.875rem', fontWeight: '500', color: '#374151' }}>Overall Score</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Star style={{ height: '1rem', width: '1rem', color: '#FFD700', marginRight: '0.25rem' }} />
            <span style={{ fontSize: '1.125rem', fontWeight: '600', color: '#1f9ad6' }}>
              {session.Overall_Average.toFixed(1)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// SessionCards Component
export function SessionCards({ 
  discoverySessions, 
  strategistSessions,
  activeTab,
  onTabChange
}) {
  const [discoveryPage, setDiscoveryPage] = useState(1);
  const [strategistPage, setStrategistPage] = useState(1);
const [duration,setDuration] = useState("all")
  const [score,setScore] = useState("all")
  const [filteredDiscovery,setFilteredDiscovery] = useState([])
  const [filteredStrategist,setFilteredStrategist] = useState([])
  useEffect(() => {
    if (discoverySessions.length >= 0) {
      let filteredData = discoverySessions;
  
      // Apply duration filter
      if (duration !== "all") {
        if (duration === "90+") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) > 90
          );
        } 
        else if (duration == "60-90") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) >= 60 &&
              Number(data.Average_duration) <= 90
          );}
        else if (duration === "45-60") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) < 60 &&
              Number(data.Average_duration) > 45
          );
        } else if (duration === "<45") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) < 45
          );
        }
      }
  
      // Apply score filter
      if (score !== "all") {
        if (score === "4-5") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 4 && Number(data.Overall_Average) <= 5
          );
        } else if (score === "3-4") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 3 && Number(data.Overall_Average) < 4
          );
        } else if (score === "<3") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) < 3
          );
        }
      }
  
      setFilteredDiscovery(filteredData);
    }

    if (strategistSessions.length >= 0) {
      let filteredData = strategistSessions;
      if (duration !== "all") {
        if (duration === "90+") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) > 90
          );
        } 
        else if (duration == "60-90") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) >= 60 &&
              Number(data.Average_duration) <= 90
          );}
        else if (duration === "45-60") {
          filteredData = filteredData.filter(
            (data) =>
              Number(data.Average_duration) < 60 &&
              Number(data.Average_duration) > 45
          );
        } else if (duration === "<45") {
          filteredData = filteredData.filter(
            (data) => Number(data.Average_duration) < 45
          );
        }
      }
  
      // Apply score filter
      if (score !== "all") {
        if (score === "4-5") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 4 && Number(data.Overall_Average) <= 5
          );
        } else if (score === "3-4") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) >= 3 && Number(data.Overall_Average) < 4
          );
        } else if (score === "<3") {
          filteredData = filteredData.filter(
            (data) => Number(data.Overall_Average) < 3
          );
        }
      }
      setFilteredStrategist(filteredData);

    }
  }, [duration, score, discoverySessions,strategistSessions])

  const itemsPerPage = 6;

  const getPageItems = (items, page) => {
    const start = (page - 1) * itemsPerPage;
    return items.slice(start, start + itemsPerPage);
  };

  const discoveryTotalPages = Math.ceil(filteredDiscovery.length / itemsPerPage);
  const strategistTotalPages = Math.ceil(filteredStrategist.length / itemsPerPage);
 const exportToSpecialistExcel = () => {
    const headers = [
      "Specialist",
      "Email",
      "Introduction",
      "Cash Flow Tour",
      "Closing & Follow Up",
      "Freedom Method",
      "Member Understanding",
      "Preparation",
      "The Gap",
      "Unpack",
      "Avg Duration",
      "Overall Score"
    ];
  
    // Convert table data
    const data = filteredDiscovery.map((session) => [
      session.name,
      session.email,
      (Math.floor(session.Criteria_Averages.introduction * 10)/10),
      (Math.floor(session.Criteria_Averages.cash_flow_tour * 10)/10),
      (Math.floor(session.Criteria_Averages.closing_and_follow_up * 10)/10),
      (Math.floor(session.Criteria_Averages.freedom_method_presentation * 10)/10),
      (Math.floor(session.Criteria_Averages.member_understanding_and_journey * 10)/10),
      (Math.floor(session.Criteria_Averages.preparation * 10)/10),
      (Math.floor(session.Criteria_Averages.the_gap * 10)/10),
      (Math.floor(session.Criteria_Averages.unpack * 10)/10),
      (Math.floor(session.Average_duration * 10)/10),
      (Math.floor(session.Overall_Average * 10)/10)
    ]);
  
    // Add headers
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Discovery Data");
  
    // Write file and trigger download
    XLSX.writeFile(workbook, "discovery_data.xlsx");
  };
  
  const exportToStrategistExcel = () => {
    const headers = [
      "Strategist",
      "Email",
      "Rapport Build",
      "Qualifying Appointment",
      "Set Tone Early",
      "Set Agenda",
      "UNPACK - Discovery",
      "Freedom Knowledge",
      "Personal Story",
      "Finance Assessment 1",
      "Finance Assessment 2",
      "Lazy Equity",
      "Cashflow Sheet",
      "Property Planner",
      "Freedom Criteria",
      "Recap Decision",
      "Session Time",
      "Ask for Referral",
      "Expectation",
      "Final Recap",
      "Avg Duration",
      "Overall Score"
    ];
    // Convert table data
    const data = filteredStrategist.map((session) => [
      session.name,
      session.email,
        (Math.floor(session.Criteria_Averages.rapport_building *10)/10),
        (Math.floor(session.Criteria_Averages.appointment_qualification *10)/10),
        (Math.floor(session.Criteria_Averages.set_tone_early *10)/10),
        (Math.floor(session.Criteria_Averages.set_agenda *10)/10),
        (Math.floor(session.Criteria_Averages.discovery_questions *10)/10),
        (Math.floor(session.Criteria_Averages.knowledge_check *10)/10),
        (Math.floor(session.Criteria_Averages.personal_story *10)/10),
        (Math.floor(session.Criteria_Averages.finance_assessment_page1 *10)/10),
        (Math.floor(session.Criteria_Averages.finance_assessment_page2 *10)/10),
        (Math.floor(session.Criteria_Averages.lazy_equity_smsf *10)/10),
        (Math.floor(session.Criteria_Averages.cashflow_sheet *10)/10),
        (Math.floor(session.Criteria_Averages.ultimate_property_planner *10)/10),
        (Math.floor(session.Criteria_Averages.freedom_criteria *10)/10),
        (Math.floor(session.Criteria_Averages.recap_decision *10)/10),
        (Math.floor(session.Criteria_Averages.secure_property_session *10)/10),
        (Math.floor(session.Criteria_Averages.ask_for_referral *10)/10),
        (Math.floor(session.Criteria_Averages.expectations_decision *10)/10),
        (Math.floor(session.Criteria_Averages.final_recap *10)/10),
        (Math.floor(session.Average_duration *10)/10),
        (Math.floor(session.Overall_Average *10)/10),
    ]);
  
    // Add headers
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Discovery Data");
  
    // Write file and trigger download
    XLSX.writeFile(workbook, "Strategy_data.xlsx");
  };
  return (
    <div style={{ padding: '24px' }}>
      {activeTab === 'discovery' ? (
        <>
          {/* Filters for Discovery Sessions */}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
            marginBottom: '16px'
          }}>
            {/* Duration Filter */}
            <div style={{ position: 'relative' }}>
              <select
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}

              >
                <option value="all">All Duration</option>
                <option value="90+">More than 90 minutes</option>
                <option value="60-90">Between 60-90 minutes</option>
                <option value="45-60">Between 45-60 minutes</option>
                <option value="<45">Less than 45 minutes</option>
              </select>
              <Clock style={{
                position: 'absolute',
                left: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                height: '16px',
                width: '16px',
                color: '#888'
              }} />
            </div>

            {/* Score Filter */}
            <div style={{ position: 'relative' }}>
              <select
               value={score}
               onChange={(e) => setScore(e.target.value)}
               style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}

              >
                <option value="all">All Score</option>
                <option value="4-5">4-5</option>
                <option value="3-4">3-4</option>
                <option value="<3">Under 3</option>
              </select>
              <Star style={{
                position: 'absolute',
                left: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                height: '16px',
                width: '16px',
                color: '#888'
              }} />
            </div>

            {/* Export Button */}
            <div style={{ position: 'relative' }}>
  <button
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      padding: "0.375rem 1rem",
      borderRadius: "0.375rem",
      borderWidth: "1px",
      borderColor: "#D1D5DB",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      outline: "none",
      borderStyle: "solid",
      boxShadow: "none"
    }}
    onClick={() => exportToSpecialistExcel()}
  >
    Export Data
    <Download
      style={{
        height: '16px',
        width: '16px',
        color: '#888',
      }}
    />
  </button>
</div>

          </div>

          {/* Discovery Card Grid */}
          <div className='specialistdiv'>
            {getPageItems(filteredDiscovery, discoveryPage).map((session, index) => (
              <SpecialistCard key={index} session={session} />
            ))}
          </div>
          {/* Pagination for Discovery Sessions */}
          <Pagination
            currentPage={discoveryPage}
            totalPages={discoveryTotalPages}
            onPageChange={(page) => setDiscoveryPage(page)}
          />
        </>
      ) : (
        <>
          {/* Strategist Filters */}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
            marginBottom: '16px'
          }}>
            {/* Duration Filter */}
            <div style={{ position: 'relative' }}>
              <select
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}

              >
                <option value="all">All Duration</option>
                <option value="90+">More than 90 minutes</option>
                <option value="60-90">Between 60-90 minutes</option>
                <option value="45-60">Between 45-60 minutes</option>
                <option value="<45">Less than 45 minutes</option>
              </select>
              <Clock style={{
                position: 'absolute',
                left: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                height: '16px',
                width: '16px',
                color: '#888'
              }} />
            </div>

            {/* Score Filter */}
            <div style={{ position: 'relative' }}>
              <select
                  value={score}
                  onChange={(e) => setScore(e.target.value)}
                  style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}

              >
                <option value="all">All Score</option>
                <option value="4-5">4-5</option>
                <option value="3-4">3-4</option>
                <option value="<3">Under 3</option>
              </select>
              <Star style={{
                position: 'absolute',
                left: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                height: '16px',
                width: '16px',
                color: '#888'
              }} />
            </div>

            {/* Export Button */}
            <div style={{ position: 'relative' }}>
  <button
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      padding: "0.375rem 1rem",
      borderRadius: "0.375rem",
      borderWidth: "1px",
      borderColor: "#D1D5DB",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      outline: "none",
      borderStyle: "solid",
      boxShadow: "none"
    }}
    onClick={() => exportToStrategistExcel()}
  >
    Export Data
    <Download
      style={{
        height: '16px',
        width: '16px',
        color: '#888',
      }}
    />
  </button>
</div>

          </div>
{/* Strategist Card Grid */}
<div className='specialistdiv'>
            {getPageItems(filteredStrategist, strategistPage).map((session, index) => (
              <StrategistCard key={index} session={session} />
            ))}
          </div>
          {/* Pagination for Strategist Sessions */}
          <Pagination
            currentPage={strategistPage}
            totalPages={strategistTotalPages}
            onPageChange={(page) => setStrategistPage(page)}
          />
        </>
      )}
    </div>
  );
};

export default SessionCards;