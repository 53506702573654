import { axiosInstance } from "../utills"

export const LoginUser = async(email,password) =>{
    try{
        const response = await axiosInstance.get(
            `https://zoom.kuru.ai/authenticate_user?email=${email}&password=${password}`
        );

        // Check if login is successful
        if (response.data.message == `Authentiation succesfull for ${email}`) {
            return true;
        } else {
            return false;
        }    }

    catch(error)
    {
        console.error('Error exporting data:', error);
        if (error.response) {
            console.error('Server responded with:', error.response.data);  
        }
        throw new Error('Failed to export data. Please try again later.');
    
    }
}