// SessionDetail.js
import React, { useEffect, useRef, useState } from "react";
import {
  ArrowLeft,
  LinkIcon,
  Pin,
  Video

} from "lucide-react";
import SessionVideo from "./sessiondetails-components/SessionVideo";
import SessionRating from "./sessiondetails-components/SessionRating";
import SessionScore from "./sessiondetails-components/SessionScore";
import TopicsCovered from "./sessiondetails-components/TopicsCovered";
import SessionSummary from "./sessiondetails-components/SessionSummary";
import SessionStrength from "./sessiondetails-components/SessionStrength";
import SessionWeakness from "./sessiondetails-components/SessionWeakness";
import SessionRecommendations from "./sessiondetails-components/SessionRecommendations";
import SessionFlags from "./sessiondetails-components/SessionFlags";
import SessionConclusion from "./sessiondetails-components/SessionConclusion";
import FeedbackButton from "./FeedbackButton";
import PerformanceSummary from "./PerformanceSummary";
import { CriteriaList } from "./CriteriaList";
import CriterionCard from "./sessiondetails-components/CriterionCard";

export function SessionDetail({ session, onBack }) {
  const [isSticky, setIsSticky] = useState(false);
  const [doneCount, setDoneCount] = useState(0);
  const [notDoneCount, setNotDoneCount] = useState(0);
  const performanceRef = useRef(null);
  const mockCriteriaData = {
    "Introduction": {
      score: session.introduction.Rating, // Score out of 5
      analysis: session.introduction.Comments,
      strengths: session.introduction.Strengths,
      areasForImprovement: session.introduction["Areas for Improvement"],
    },
    "Clash Flow Tour": {
      score: session.cashFlowTour.Rating, // Score out of 5
      analysis: session.cashFlowTour.Comments,
      strengths: session.cashFlowTour.Strengths,
      areasForImprovement: session.cashFlowTour["Areas for Improvement"],

    },
   
    "Closing And Follow Up": {
      score: session.closingAndFollowUp.Rating, // Score out of 5
      analysis: session.closingAndFollowUp.Comments,
      strengths: session.closingAndFollowUp.Strengths,
      areasForImprovement: session.closingAndFollowUp["Areas for Improvement"],
    },
    "Freedom Method Representation": {
      score: session.FreedomMethodPresentation.Rating, // Score out of 5
      analysis: session.FreedomMethodPresentation.Comments,
      strengths: session.FreedomMethodPresentation.Strengths,
      areasForImprovement: session.FreedomMethodPresentation["Areas for Improvement"],
    },
    "Member Understanding And Journey": {
      score: session.memberUnderstandingAndJourney.Rating, // Score out of 5
      analysis: session.memberUnderstandingAndJourney.Comments,
      strengths: session.memberUnderstandingAndJourney.Strengths,
      areasForImprovement: session.memberUnderstandingAndJourney["Areas for Improvement"],
    },
    "Preparation": {
      score: session.preparation.Rating, // Score out of 5
      analysis: session.preparation.Comments,
      strengths: session.preparation.Strengths,
      areasForImprovement: session.preparation["Areas for Improvement"],
    },
    "The Gap": {
      score: session.theGap.Rating, // Score out of 5
      analysis: session.theGap.Comments,
      strengths: session.theGap.Strengths,
      areasForImprovement: session.theGap["Areas for Improvement"],
    },
    "Unpack": {
      score: session.unpack.Rating, // Score out of 5
      analysis: session.unpack.Comments,
      strengths: session.unpack.Strengths,
      areasForImprovement: session.unpack["Areas for Improvement"],
    },
  };
  const handleCriterionClick = (criterion) => {
    const element = document.getElementById(`criterion-${criterion.toLowerCase().replace(/\s+/g, '-')}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    let done = 0;
    let notDone = 0;

    const criteria = [
      session.introduction,
      session.cashFlowTour,
      session.closingAndFollowUp,
      session.FreedomMethodPresentation,
      session.memberUnderstandingAndJourney,
      session.preparation,
      session.theGap,
      session.unpack,
    ];

    criteria.forEach(criterion => {
      if (criterion.Performance === "Done") {
        done++;
      } else {
        notDone++;
      }
    });

    setDoneCount(done);
    setNotDoneCount(notDone);
  }, []);
  return (
    <div

    >
      <div className="modal-dialog ">
        <div className="modal-content">

          <div className="modal-header border-0 bg-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col">
                  <button
                    onClick={onBack}
                    className="btn btn-link text-decoration-none p-0 mb-2 d-flex align-items-center"
                  >
                    <ArrowLeft className="me-1" size={16} />
                    Back to Sessions
                  </button>
                  <h5 className="modal-title mb-3">
                    {session.title}
                  </h5>
                </div>

                <div className="col-auto d-flex align-items-center gap-2">

                  {session.videoLink && (
                    <a
                      href={session.videoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary d-flex align-items-center"

                    >
                      <Video size={16} className="me-2" />

                      Watch Recording

                      <LinkIcon size={16} />
                    </a>
                  )}
                  <FeedbackButton
                    strategistEmail={(session.representative.email)}
                    sessionTitle={session.title}
                  />
               
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {/* Left Column */}
              <div className="col-lg-8">
                <SessionVideo session={session} />

                <div
                  className="row"
                  style={{
                    margin: 0,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <SessionRating session={session} /> */}
                  {/* <SessionScore session={session} />
                  <TopicsCovered session={session} /> */}


                </div>

                {/* <SessionSummary session={session} /> */}
                <div>
                               
                      <CriterionCard criterionId={session.id} title={"Introduction"} isDone={(session.introduction.Performance == "Done") ? true : false} rating={session.introduction.Rating} comments={session.introduction.Comments} strengths={session.introduction.Strengths} areasForImprovement={session.introduction["Areas for Improvement"] }/>
                      </div>

                      <div>
                               
                               <CriterionCard criterionId={session.id} title={"Clash Flow Tour"} isDone={(session.cashFlowTour.Performance == "Done") ? true : false} rating={session.cashFlowTour.Rating} comments={session.cashFlowTour.Comments} strengths={session.cashFlowTour.Strengths} areasForImprovement={session.cashFlowTour["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Closing And Follow Up"} isDone={(session.closingAndFollowUp.Performance == "Done") ? true : false} rating={session.closingAndFollowUp.Rating} comments={session.closingAndFollowUp.Comments} strengths={session.closingAndFollowUp.Strengths} areasForImprovement={session.closingAndFollowUp["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Freedom Method Representation"} isDone={(session.FreedomMethodPresentation.Performance == "Done") ? true : false} rating={session.FreedomMethodPresentation.Rating} comments={session.FreedomMethodPresentation.Comments} strengths={session.FreedomMethodPresentation.Strengths} areasForImprovement={session.FreedomMethodPresentation["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Member Understanding And Journey"} isDone={(session.memberUnderstandingAndJourney.Performance == "Done") ? true : false} rating={session.memberUnderstandingAndJourney.Rating} comments={session.memberUnderstandingAndJourney.Comments} strengths={session.memberUnderstandingAndJourney.Strengths} areasForImprovement={session.memberUnderstandingAndJourney["Areas for Improvement"] }/>
                               </div>

                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Preparation"} isDone={(session.preparation.Performance == "Done") ? true : false} rating={session.preparation.Rating} comments={session.preparation.Comments} strengths={session.preparation.Strengths} areasForImprovement={session.preparation["Areas for Improvement"] }/>
                               </div>

                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"The Gap"} isDone={(session.theGap.Performance == "Done") ? true : false} rating={session.theGap.Rating} comments={session.theGap.Comments} strengths={session.theGap.Strengths} areasForImprovement={session.theGap["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Unpack"} isDone={(session.unpack.Performance == "Done") ? true : false} rating={session.unpack.Rating} comments={session.unpack.Comments} strengths={session.unpack.Strengths} areasForImprovement={session.unpack["Areas for Improvement"] }/>
                               </div>
                {/* <div
                  className="row mb-4"
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="h2 fw-bold mb-4" style={{ color: "rgb(17, 24, 39)", fontFamily: "system-ui" }}
                  >Performance Analysis</h3>

                  <SessionStrength session={session} />

                  <SessionWeakness session={session} />
                </div> */}

                {/* <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionRecommendations session={session} />
                </div>

                <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionFlags session={session} />
                </div>

                <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionConclusion session={session} />

                </div> */}
              </div>

              <div className="col-lg-4">
                <div
                  className={isSticky ? "position-sticky" : ""}
                  style={{ top: isSticky ? '1rem' : 'auto' }}
                  ref={performanceRef}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="card-title mb-0">Performance Criteria</h5>
                        <button
                          className={`btn btn-sm ${isSticky ? 'btn-primary' : 'btn-outline-primary'}`}
                          onClick={() => setIsSticky(!isSticky)}
                          title={isSticky ? 'Unpin from top' : 'Pin to top'}
                        >
                          <Pin size={16} className={isSticky ? 'fill-current' : ''} />
                        </button>
                      </div>
                      <PerformanceSummary
                        averageScore={session.rating}
                        doneTasks={doneCount}
                        notDoneTasks={notDoneCount}
                      />
                      <CriteriaList criteria={mockCriteriaData} onCriterionClick={handleCriterionClick} />

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
