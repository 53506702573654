// SessionDetailStrategist.js
import React, { useEffect, useRef, useState } from "react";
import {
  ArrowLeft,
  LinkIcon,
  Pin,
  Video

} from "lucide-react";
import SessionVideo from "./sessiondetails-components/SessionVideo";
import SessionRating from "./sessiondetails-components/SessionRating";
import SessionScore from "./sessiondetails-components/SessionScore";
import TopicsCovered from "./sessiondetails-components/TopicsCovered";
import SessionSummary from "./sessiondetails-components/SessionSummary";
import SessionStrength from "./sessiondetails-components/SessionStrength";
import SessionWeakness from "./sessiondetails-components/SessionWeakness";
import SessionRecommendations from "./sessiondetails-components/SessionRecommendations";
import SessionFlags from "./sessiondetails-components/SessionFlags";
import SessionConclusion from "./sessiondetails-components/SessionConclusion";
import FeedbackButton from "./FeedbackButton";
import PerformanceSummary from "./PerformanceSummary";
import { CriteriaList } from "./CriteriaList";
import CriterionCard from "./sessiondetails-components/CriterionCard";

export function SessionDetailStrategist({ session, onBack }) {
  console.log("StrategistData",session)
  const [isSticky, setIsSticky] = useState(false);
  const [doneCount, setDoneCount] = useState(0);
  const [notDoneCount, setNotDoneCount] = useState(0);
  const performanceRef = useRef(null);
  const mockCriteriaData = {
    "Rapport Build": {
      score: session.rapportBuild.Rating, // Score out of 5
      analysis: session.rapportBuild.Comments,
      strengths: session.rapportBuild.Strengths,
      areasForImprovement: session.rapportBuild["Areas for Improvement"],
    },
    "Qualifying the Appointment": {
      score: session.qualifyingAppointment.Rating, // Score out of 5
      analysis: session.qualifyingAppointment.Comments,
      strengths: session.qualifyingAppointment.Strengths,
      areasForImprovement: session.qualifyingAppointment["Areas for Improvement"],

    },
   
    "Set the Tone Early": {
      score: session.setTheToneEarly.Rating, // Score out of 5
      analysis: session.setTheToneEarly.Comments,
      strengths: session.setTheToneEarly.Strengths,
      areasForImprovement: session.setTheToneEarly["Areas for Improvement"],
    },
    "Set an Agenda": {
      score: session.setAnAgenda.Rating, // Score out of 5
      analysis: session.setAnAgenda.Comments,
      strengths: session.setAnAgenda.Strengths,
      areasForImprovement: session.setAnAgenda["Areas for Improvement"],
    },
    "UNPACK - Discovery Questions": {
      score: session.unpackDiscovery.Rating, // Score out of 5
      analysis: session.unpackDiscovery.Comments,
      strengths: session.unpackDiscovery.Strengths,
      areasForImprovement: session.unpackDiscovery["Areas for Improvement"],
    },
    "Freedom Knowledge": {
      score: session.freedomKnowledge.Rating, // Score out of 5
      analysis: session.freedomKnowledge.Comments,
      strengths: session.freedomKnowledge.Strengths,
      areasForImprovement: session.freedomKnowledge["Areas for Improvement"],
    },
    "Personal Story": {
      score: session.personalStory.Rating, // Score out of 5
      analysis: session.personalStory.Comments,
      strengths: session.personalStory.Strengths,
      areasForImprovement: session.personalStory["Areas for Improvement"],
    },
    // "Session Expectations": {
    //   score: session.sessionExpectations.Rating, // Score out of 5
    //   analysis: session.sessionExpectations.Comments,
    //   strengths: session.sessionExpectations.Strengths,
    //   areasForImprovement: session.sessionExpectations["Areas for Improvement"],
    // },
    "Finance Assessment 1": {
      score: session.financeAssessment1.Rating, // Score out of 5
      analysis: session.financeAssessment1.Comments,
      strengths: session.financeAssessment1.Strengths,
      areasForImprovement: session.financeAssessment1["Areas for Improvement"],
    },
    "Finance Assessment 2": {
      score: session.financeAssessment2.Rating, // Score out of 5
      analysis: session.financeAssessment2.Comments,
      strengths: session.financeAssessment2.Strengths,
      areasForImprovement: session.financeAssessment2["Areas for Improvement"],
    },
    "Lazy Equity": {
      score: session.lazyEquity.Rating, // Score out of 5
      analysis: session.lazyEquity.Comments,
      strengths: session.lazyEquity.Strengths,
      areasForImprovement: session.lazyEquity["Areas for Improvement"],
    },
    "Cashflow Sheet": {
      score: session.cashFlow.Rating, // Score out of 5
      analysis: session.cashFlow.Comments,
      strengths: session.cashFlow.Strengths,
      areasForImprovement: session.cashFlow["Areas for Improvement"],
    },
    "Ultimate Property Planner": {
      score: session.ultimateProperty.Rating, // Score out of 5
      analysis: session.ultimateProperty.Comments,
      strengths: session.ultimateProperty.Strengths,
      areasForImprovement: session.ultimateProperty["Areas for Improvement"],
    },
    "Freedom Criteria": {
      score: session.freedomCriteria.Rating, // Score out of 5
      analysis: session.freedomCriteria.Comments,
      strengths: session.freedomCriteria.Strengths,
      areasForImprovement: session.freedomCriteria["Areas for Improvement"],
    },
    "Recap + Get Member Decision": {
      score: session.recapDecision.Rating, // Score out of 5
      analysis: session.recapDecision.Comments,
      strengths: session.recapDecision.Strengths,
      areasForImprovement: session.recapDecision["Areas for Improvement"],
    },
    "Secure Property Session Time": {
      score: session.securePropertySession.Rating, // Score out of 5
      analysis: session.securePropertySession.Comments,
      strengths: session.securePropertySession.Strengths,
      areasForImprovement: session.securePropertySession["Areas for Improvement"],
    },
    "Ask for Referral": {
      score: session.askForReferral.Rating, // Score out of 5
      analysis: session.askForReferral.Comments,
      strengths: session.askForReferral.Strengths,
      areasForImprovement: session.askForReferral["Areas for Improvement"],
    },
    "Expectation Decision":{
      score: session.expectations_decision.Rating, // Score out of 5
      analysis: session.expectations_decision.Comments,
      strengths: session.expectations_decision.Strengths,
      areasForImprovement: session.expectations_decision["Areas for Improvement"],
    },
    "Final Recap": {
      score: session.finalRecap.Rating, // Score out of 5
      analysis: session.finalRecap.Comments,
      strengths: session.finalRecap.Strengths,
      areasForImprovement: session.finalRecap["Areas for Improvement"],
    },
  
  };
  const handleCriterionClick = (criterion) => {
    const element = document.getElementById(`criterion-${criterion.toLowerCase().replace(/\s+/g, '-')}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    let done = 0;
    let notDone = 0;

    // console.log("Session value is", session)
    const criteria = [
      session.rapportBuild,
      session.qualifyingAppointment,
      session.setTheToneEarly,
      session.setAnAgenda,
      session.unpackDiscovery,
      session.freedomKnowledge,
      session.personalStory,
      // session.sessionExpectations,
      session.financeAssessment1,
      session.financeAssessment2,
      session.lazyEquity,
      session.cashFlow,
      session.ultimateProperty,
      session.freedomCriteria,
      session.recapDecision,
      session.securePropertySession,
      session.askForReferral,
      session.expectations_decision,
      session.finalRecap,
    ];

    criteria.forEach(criterion => {
      if (criterion.Performance === "Done") {
        done++;
      } else {
        notDone++;
      }
    });

    setDoneCount(done);
    setNotDoneCount(notDone);
  }, []);
  let totalScore = 0;
  let count = 0;
  if(session.representative.role == "Strategist")
  // Calculate days ago
{

const sections = [
  session.rapportBuild.Rating,
  session.qualifyingAppointment.Rating,
  session.setTheToneEarly.Rating,
  session.setAnAgenda.Rating,
  session.unpackDiscovery.Rating,
  session.freedomKnowledge.Rating,
  session.personalStory.Rating,
  session.financeAssessment1.Rating,
  session.financeAssessment2.Rating,
  session.lazyEquity.Rating,
  session.cashFlow.Rating,
  session.ultimateProperty.Rating,
  session.freedomCriteria.Rating,
  session.recapDecision.Rating,
  session.securePropertySession.Rating,
  session.askForReferral.Rating,
  session.expectations_decision.Rating,
  session.finalRecap.Rating
];

sections.forEach(score => {
  if (score !== undefined) { // Ensure the score is valid
    totalScore += Number(score);
    count++;
  }
});
}
const averageScore = totalScore / count;
  return (
    <div

    >
      <div className="modal-dialog ">
        <div className="modal-content">

          <div className="modal-header border-0 bg-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col">
                  <button
                    onClick={onBack}
                    className="btn btn-link text-decoration-none p-0 mb-2 d-flex align-items-center"
                  >
                    <ArrowLeft className="me-1" size={16} />
                    Back to Sessions
                  </button>
                  <h5 className="modal-title mb-3">
                    {session.title}
                  </h5>
                </div>

                <div className="col-auto d-flex align-items-center gap-2">

                  {session.videoLink && (
                    <a
                      href={session.videoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary d-flex align-items-center"

                    >
                      <Video size={16} className="me-2" />

                      Watch Recording

                      <LinkIcon size={16} />
                    </a>
                  )}
                  <FeedbackButton
                    strategistEmail={(session.representative.email)}
                    sessionTitle={session.title}
                  />
            
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {/* Left Column */}
              <div className="col-lg-8">
                <SessionVideo session={session} />

                <div
                  className="row"
                  style={{
                    margin: 0,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <SessionRating session={session} /> */}
                  {/* <SessionScore session={session} />
                  <TopicsCovered session={session} /> */}


                </div>

                {/* <SessionSummary session={session} /> */}
                <div>
                               
                      <CriterionCard criterionId={session.id} title={"Rapport Build"} isDone={(session.rapportBuild.Performance == "Done") ? true : false} rating={session.rapportBuild.Rating} comments={session.rapportBuild.Comments} strengths={session.rapportBuild.Strengths} areasForImprovement={session.rapportBuild["Areas for Improvement"] }/>
                      </div>

                      <div>
                               
                               <CriterionCard criterionId={session.id} title={"Qualifying the Appointment"} isDone={(session.qualifyingAppointment.Performance == "Done") ? true : false} rating={session.qualifyingAppointment.Rating} comments={session.qualifyingAppointment.Comments} strengths={session.qualifyingAppointment.Strengths} areasForImprovement={session.qualifyingAppointment["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Set the Tone Early"} isDone={(session.setTheToneEarly.Performance == "Done") ? true : false} rating={session.setTheToneEarly.Rating} comments={session.setTheToneEarly.Comments} strengths={session.setTheToneEarly.Strengths} areasForImprovement={session.setTheToneEarly["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Set an Agenda"} isDone={(session.setAnAgenda.Performance == "Done") ? true : false} rating={session.setAnAgenda.Rating} comments={session.setAnAgenda.Comments} strengths={session.setAnAgenda.Strengths} areasForImprovement={session.setAnAgenda["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"UNPACK - Discovery Questions"} isDone={(session.unpackDiscovery.Performance == "Done") ? true : false} rating={session.unpackDiscovery.Rating} comments={session.unpackDiscovery.Comments} strengths={session.unpackDiscovery.Strengths} areasForImprovement={session.unpackDiscovery["Areas for Improvement"] }/>
                               </div>

                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Freedom Knowledge"} isDone={(session.freedomKnowledge.Performance == "Done") ? true : false} rating={session.freedomKnowledge.Rating} comments={session.freedomKnowledge.Comments} strengths={session.freedomKnowledge.Strengths} areasForImprovement={session.freedomKnowledge["Areas for Improvement"] }/>
                               </div>

                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Personal Story"} isDone={(session.personalStory.Performance == "Done") ? true : false} rating={session.personalStory.Rating} comments={session.personalStory.Comments} strengths={session.personalStory.Strengths} areasForImprovement={session.personalStory["Areas for Improvement"] }/>
                               </div>
                               {/* <div>
                               
                               <CriterionCard criterionId={session.id} title={"Session Expectations"} isDone={(session.sessionExpectations.Performance == "Done") ? true : false} rating={session.sessionExpectations.Rating} comments={session.sessionExpectations.Comments} strengths={session.sessionExpectations.Strengths} areasForImprovement={session.sessionExpectations["Areas for Improvement"] }/>
                               </div> */}
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Finance Assessment 1"} isDone={(session.financeAssessment1.Performance == "Done") ? true : false} rating={session.financeAssessment1.Rating} comments={session.financeAssessment1.Comments} strengths={session.financeAssessment1.Strengths} areasForImprovement={session.financeAssessment1["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Finance Assessment 2"} isDone={(session.financeAssessment2.Performance == "Done") ? true : false} rating={session.financeAssessment2.Rating} comments={session.financeAssessment2.Comments} strengths={session.financeAssessment2.Strengths} areasForImprovement={session.financeAssessment2["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Lazy Equity"} isDone={(session.lazyEquity.Performance == "Done") ? true : false} rating={session.lazyEquity.Rating} comments={session.lazyEquity.Comments} strengths={session.lazyEquity.Strengths} areasForImprovement={session.lazyEquity["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Cashflow Sheet"} isDone={(session.cashFlow.Performance == "Done") ? true : false} rating={session.cashFlow.Rating} comments={session.cashFlow.Comments} strengths={session.cashFlow.Strengths} areasForImprovement={session.cashFlow["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Ultimate Property Planner"} isDone={(session.ultimateProperty.Performance == "Done") ? true : false} rating={session.ultimateProperty.Rating} comments={session.ultimateProperty.Comments} strengths={session.ultimateProperty.Strengths} areasForImprovement={session.ultimateProperty["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Freedom Criteria"} isDone={(session.freedomCriteria.Performance == "Done") ? true : false} rating={session.freedomCriteria.Rating} comments={session.freedomCriteria.Comments} strengths={session.freedomCriteria.Strengths} areasForImprovement={session.freedomCriteria["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Recap + Get Member Decision"} isDone={(session.recapDecision.Performance == "Done") ? true : false} rating={session.recapDecision.Rating} comments={session.recapDecision.Comments} strengths={session.recapDecision.Strengths} areasForImprovement={session.recapDecision["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Secure Property Session Time"} isDone={(session.securePropertySession.Performance == "Done") ? true : false} rating={session.securePropertySession.Rating} comments={session.securePropertySession.Comments} strengths={session.securePropertySession.Strengths} areasForImprovement={session.securePropertySession["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Ask for Referral"} isDone={(session.askForReferral.Performance == "Done") ? true : false} rating={session.askForReferral.Rating} comments={session.askForReferral.Comments} strengths={session.askForReferral.Strengths} areasForImprovement={session.askForReferral["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Expectation Decision"} isDone={(session.expectations_decision.Performance == "Done") ? true : false} rating={session.expectations_decision.Rating} comments={session.expectations_decision.Comments} strengths={session.expectations_decision.Strengths} areasForImprovement={session.expectations_decision["Areas for Improvement"] }/>
                               </div>
                               <div>
                               
                               <CriterionCard criterionId={session.id} title={"Final Recap"} isDone={(session.finalRecap.Performance == "Done") ? true : false} rating={session.finalRecap.Rating} comments={session.finalRecap.Comments} strengths={session.finalRecap.Strengths} areasForImprovement={session.finalRecap["Areas for Improvement"] }/>
                               </div>
                               
                {/* <div
                  className="row mb-4"
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="h2 fw-bold mb-4" style={{ color: "rgb(17, 24, 39)", fontFamily: "system-ui" }}
                  >Performance Analysis</h3>

                  <SessionStrength session={session} />

                  <SessionWeakness session={session} />
                </div> */}

                {/* <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionRecommendations session={session} />
                </div>

                <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionFlags session={session} />
                </div>

                <div className="row mt-4" style={{ marginLeft: 0, marginRight: 0 }}>
                  <SessionConclusion session={session} />

                </div> */}
              </div>

              <div className="col-lg-4">
                <div
                  className={isSticky ? "position-sticky" : ""}
                  style={{ top: isSticky ? '1rem' : 'auto' }}
                  ref={performanceRef}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="card-title mb-0">Performance Criteria</h5>
                        <button
                          className={`btn btn-sm ${isSticky ? 'btn-primary' : 'btn-outline-primary'}`}
                          onClick={() => setIsSticky(!isSticky)}
                          title={isSticky ? 'Unpin from top' : 'Pin to top'}
                        >
                          <Pin size={16} className={isSticky ? 'fill-current' : ''} />
                        </button>
                      </div>
                      <PerformanceSummary
                        averageScore={averageScore.toFixed(1)}
                        doneTasks={doneCount}
                        notDoneTasks={notDoneCount}
                      />
                      <CriteriaList criteria={mockCriteriaData} onCriterionClick={handleCriterionClick} />

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
