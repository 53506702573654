import "./App.css";
import Login from "./Pages/Login/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import ErrorPage from "./Pages/Error";
import Root from "./Pages/Root/Root";
import Dashboard from "./Pages/Dashboard/Dashboard";
import User from "./Pages/User/User";
import Blog from "./Pages/Blog/Blog";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import Leads from "./Pages/Lead/Leads";
import Settings from "./Pages/Settings/Settings";
import Inbox from "./Pages/Inbox";
import ZoomAnalysis from "./Pages/ZoomAnalysis";
import ZoomRoot from "./Pages/ZoomRoot/ZoomRoot";
import SpecificZoomAnalysis from "./Pages/SpecificZoomAnalysis";
import SessionSummary from "./Pages/SessionSummary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/staff",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: (<ProtectedRoute> <Privacy /> </ProtectedRoute>),
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-conditions",
    element: (
      <ProtectedRoute>

    <Terms />
    </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "users", element: <User /> },
      { path: "blog", element: <Blog /> },
      { path: "inbox", element: <Inbox /> },
      { path: "leads", element: <Leads /> },
      { path: "zoom-analysis", element: <ZoomAnalysis /> },
      { path: "sessionsummary", element: <SessionSummary /> },
      { path: "settings", element: <Settings /> },
    ],
  },
  {
    path: "/zoom-dashboard",
    element: (
      <ProtectedRoute>
        <ZoomRoot />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <SpecificZoomAnalysis /> },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
