import { axiosInstance } from "../utills";

export const getSummaryAnalysis = async(fromdate,todate,role)=>{
    console.log(fromdate,todate,role)
    try{
        const response = await axiosInstance.get(`https://zoom.kuru.ai/summary_analysis?from_date=${fromdate}&to_date=${todate}&role=${role}`)
        const arrayResult = response.data[0]
        if(role=="discovery")
        {
          return arrayResult.discovery
        }
        else{
            return arrayResult.strategy

        }
      }
      catch(error)
      {
          console.error('Error exporting data:', error);
          if (error.response) {
              console.error('Server responded with:', error.response.data);  
          }
          throw new Error('Failed to export data. Please try again later.');
      
      }
}