import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Users } from "lucide-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const discoveryMetrics = {
  introduction: { data: [3.5, 4.0, 3.8, 4.2, 3.9, 4.1], color: "#FF6384" },
  cashFlowTour: { data: [3.2, 3.8, 3.5, 3.9, 3.7, 4.0], color: "#36A2EB" },
  closingAndFollowUp: { data: [3.8, 3.6, 3.9, 4.1, 3.8, 4.2], color: "#FFCE56" },
  freedomMethod: { data: [3.4, 3.7, 3.6, 3.8, 4.0, 3.9], color: "#4BC0C0" },
  memberUnderstanding: { data: [3.6, 3.9, 3.7, 4.0, 3.8, 4.1], color: "#9966FF" },
  preparation: { data: [3.3, 3.5, 3.8, 3.7, 3.9, 4.0], color: "#FF9F40" },
  theGap: { data: [3.7, 3.8, 3.6, 3.9, 4.1, 4.0], color: "#FF6384" },
  unpack: { data: [3.5, 3.7, 3.9, 3.8, 4.0, 4.2], color: "#4BC0C0" },
  overallScore: { data: [3.6, 3.8, 3.7, 3.9, 3.9, 4.1], color: "#1f9ad6" },
};

const strategistMetrics = {
  rapportBuild: { data: [3.4, 3.7, 3.9, 3.8, 4.0, 4.2], color: "#FF6384" },
  qualifyingAppointment: { data: [3.6, 3.8, 3.7, 3.9, 4.1, 4.0], color: "#36A2EB" },
  setToneEarly: { data: [3.3, 3.6, 3.8, 3.7, 3.9, 4.1], color: "#FFCE56" },
  setAgenda: { data: [3.5, 3.7, 3.6, 3.8, 4.0, 3.9], color: "#4BC0C0" },
  unpackDiscoveryQuestions: { data: [3.7, 3.9, 3.8, 4.0, 4.2, 4.1], color: "#9966FF" },
  freedomKnowledge: { data: [3.4, 3.6, 3.8, 3.7, 3.9, 4.0], color: "#FF9F40" },
  personalStory: { data: [3.6, 3.8, 3.7, 3.9, 4.1, 4.0], color: "#FF6384" },
  financeAssessment1: { data: [3.3, 3.5, 3.7, 3.6, 3.8, 3.9], color: "#36A2EB" },
  financeAssessment2: { data: [3.5, 3.7, 3.6, 3.8, 4.0, 3.9], color: "#FFCE56" },
  lazyEquity: { data: [3.4, 3.6, 3.8, 3.7, 3.9, 4.0], color: "#4BC0C0" },
  cashflowSheet: { data: [3.6, 3.8, 3.7, 3.9, 4.1, 4.0], color: "#9966FF" },
  ultimatePropertyPlanner: { data: [3.3, 3.5, 3.7, 3.6, 3.8, 3.9], color: "#FF9F40" },
  freedomCriteria: { data: [3.5, 3.7, 3.6, 3.8, 4.0, 3.9], color: "#FF6384" },
  recapGetMemberDecision: { data: [3.4, 3.6, 3.8, 3.7, 3.9, 4.0], color: "#36A2EB" },
  securePropertySessionTime: { data: [3.6, 3.8, 3.7, 3.9, 4.1, 4.0], color: "#FFCE56" },
  askForReferral: { data: [3.3, 3.5, 3.7, 3.6, 3.8, 3.9], color: "#4BC0C0" },
  expectationDecision: { data: [3.5, 3.7, 3.6, 3.8, 4.0, 3.9], color: "#9966FF" },
  finalRecap: { data: [3.4, 3.6, 3.8, 3.7, 3.9, 4.0], color: "#FF9F40" },
  overallScore: { data: [3.5, 3.7, 3.8, 3.8, 4.0, 4.1], color: "#1f9ad6" },
};

const users = ["All Users", "John Doe", "Sarah Williams", "Mike Johnson", "Emma Davis", "Alex Thompson"];
const months = ["January", "February", "March", "April", "May", "June"];

export function PerformanceTrend() {
  const [activeTab, setActiveTab] = useState("discovery");
  const [selectedUser, setSelectedUser] = useState("All Users");

  const metrics = activeTab === "discovery" ? discoveryMetrics : strategistMetrics;

  const chartData = {
    labels: months,
    datasets: Object.entries(metrics).map(([key, value]) => ({
      label: key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()),
      data: value.data,
      borderColor: value.color,
      backgroundColor: `${value.color}33`,
      tension: 0.4,
      borderWidth: key === "overallScore" ? 3 : 1,
      pointRadius: key === "overallScore" ? 4 : 2,
    })),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: { mode: "index", intersect: false },
    plugins: {
      legend: {
        position: "right",
        align: "start",
        labels: { boxWidth: 12, padding: 15, font: { size: 11 } },
      },
      tooltip: { mode: "index", intersect: false },
    },
    scales: {
      y: { min: 0, max: 5, ticks: { stepSize: 1, font: { size: 11 } } },
      x: { ticks: { font: { size: 11 } } },
    },
  };

  return (
    <div style={{"padding":"1.5rem","borderRadius":"0.5rem","backgroundColor":"#ffffff","boxShadow":"0 1px 2px 0 rgba(0, 0, 0, 0.05)"}}>
      <h2 style={{ fontSize: "1.25rem", fontWeight: "600", color: "#1a202c", marginBottom: "1rem" }}>Performance Trend</h2>
      <div style={{ display: "flex", borderBottom: "1px solid #e2e8f0", marginBottom: "1rem" }}>
        <button
           style={{
            padding: "0.5rem 1rem",
            fontSize: "0.875rem",
            fontWeight: "500",
            border: "none",
            borderBottom: activeTab === "discovery" ? "2px solid #1f9ad6" : "2px solid transparent",
            color: activeTab === "discovery" ? "#1f9ad6" : "#6c757d",
            backgroundColor: "transparent",
          }}
          onClick={() => setActiveTab("discovery")}
        >
          Discovery Session
        </button>
        <button
        style={{
            padding: "0.5rem 1rem",
            fontSize: "0.875rem",
            fontWeight: "500",
            border: "none",
            borderBottom: activeTab === "strategist" ? "2px solid #1f9ad6" : "2px solid transparent",
            color: activeTab === "strategist" ? "#1f9ad6" : "#6c757d",
            backgroundColor: "transparent",
          }}
          onClick={() => setActiveTab("strategist")}
        >
          Strategist Session
        </button>
      </div>
      <div style={{"display":"flex","marginBottom":"1.5rem","justifyContent":"flex-end","alignItems":"center"}}>
        <div style={{"position":"relative"}}>
        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          style={{"paddingTop":"0.375rem","paddingBottom":"0.375rem","paddingRight":"1rem","paddingLeft":"2rem","borderRadius":"0.375rem","borderWidth":"1px","borderColor":"#D1D5DB","fontSize":"0.875rem","lineHeight":"1.25rem","backgroundColor":"#ffffff","appearance":"none",":hover":{"borderColor":"#9CA3AF"}}}
        >
          {users.map((user) => (
            <option key={user} value={user}>
              {user}
            </option>
          ))}
        </select>
        <Users style={{"position":"absolute","left":"0.5rem","top":"30%","width":"1rem","height":"1rem","color":"#9CA3AF","pointerEvents":"none"}} />
      </div>
      </div>
      <div style={{ height: "300px" }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}
