import { useEffect, useState } from "react";
import { KeyMetrics } from "../component/KeyMetrics";
import SessionViews from "../component/SessionViews";
import SummaryDateRangeFilter from "../component/SummarDateRangeFilter";
import { PerformanceTrend } from "../component/PerformanceTrend";
import { PerformanceDetails } from "../component/PerformanceDetails";
import { getSummaryAnalysis } from "../api/summaryAnalysisApi";
const performanceMetrics = {
    averageScore: 4.2,
    totalSessions: 156,
    averageDuration: '45 mins',
    topPerformers: ['John Doe', 'Sarah Williams', 'Mike Johnson', 'Emma Davis', 'Alex Thompson'],
    monthlyTrend: [3.8, 4.0, 4.2, 4.5, 4.2, 4.3],
    criteriaBreakdown: [
        { name: 'Introduction', score: 4.5 },
        { name: 'Technical Knowledge', score: 4.2 },
        { name: 'Communication', score: 4.3 },
        { name: 'Problem Solving', score: 4.0 },
        { name: 'Client Engagement', score: 4.4 }
    ]
};
// Helper function to generate random score between 3.5 and 5.0
const randomScore = () => Math.round((Math.random() * 1.5 + 3.5) * 10) / 10;

// Helper function to generate random duration between 30 and 90 minutes
const randomDuration = () => `${Math.floor(Math.random() * 60 + 30)} mins`;
// const discoverySessions = [
//     {
//       specialist: 'John Doe',
//       specialistEmail: 'john@example.com',
//       introduction: 4.5,
//       cashFlowTour: 4.2,
//       closingAndFollowUp: 4.3,
//       freedomMethodRepresentation: 4.1,
//       memberUnderstandingAndJourney: 4.4,
//       preparation: 4.2,
//       theGap: 4.0,
//       unpack: 4.3,
//       avgDuration: '45 mins',
//       overallScore: 4.25
//     },
//     {
//       specialist: 'Sarah Williams',
//       specialistEmail: 'sarah@example.com',
//       introduction: 4.3,
//       cashFlowTour: 4.4,
//       closingAndFollowUp: 4.2,
//       freedomMethodRepresentation: 4.3,
//       memberUnderstandingAndJourney: 4.1,
//       preparation: 4.4,
//       theGap: 4.2,
//       unpack: 4.1,
//       avgDuration: '52 mins',
//       overallScore: 4.20
//     },
//     // Additional 5 sessions to make total of 7
//     ...[
//       'Emma Davis',
//       'Michael Brown',
//       'Jessica White',
//       'Daniel Lee',
//       'Rachel Green'
//     ].map(name => ({
//       specialist: name,
//       specialistEmail: `${name.toLowerCase().replace(' ', '.')}@example.com`,
//       introduction: randomScore(),
//       cashFlowTour: randomScore(),
//       closingAndFollowUp: randomScore(),
//       freedomMethodRepresentation: randomScore(),
//       memberUnderstandingAndJourney: randomScore(),
//       preparation: randomScore(),
//       theGap: randomScore(),
//       unpack: randomScore(),
//       avgDuration: randomDuration(),
//       overallScore: randomScore()
//     }))
//   ];

//   // Generate exactly 7 strategist sessions
//   const strategistSessions = [
//     {
//       strategist: 'Jane Smith',
//       strategistEmail: 'jane@example.com',
//       rapportBuild: 4.5,
//       qualifyingAppointment: 4.3,
//       setToneEarly: 4.2,
//       setAgenda: 4.4,
//       unpackDiscoveryQuestions: 4.1,
//       freedomKnowledge: 4.3,
//       personalStory: 4.5,
//       financeAssessment1: 4.2,
//       financeAssessment2: 4.3,
//       lazyEquity: 4.1,
//       cashflowSheet: 4.4,
//       ultimatePropertyPlanner: 4.2,
//       freedomCriteria: 4.3,
//       recapGetMemberDecision: 4.4,
//       securePropertySessionTime: 4.2,
//       askForReferral: 4.1,
//       expectationDecision: 4.3,
//       finalRecap: 4.4,
//       avgDuration: '65 mins',
//       overallScore: 4.3
//     },
//     {
//       strategist: 'Robert Wilson',
//       strategistEmail: 'robert@example.com',
//       rapportBuild: 4.3,
//       qualifyingAppointment: 4.4,
//       setToneEarly: 4.3,
//       setAgenda: 4.2,
//       unpackDiscoveryQuestions: 4.4,
//       freedomKnowledge: 4.2,
//       personalStory: 4.3,
//       financeAssessment1: 4.4,
//       financeAssessment2: 4.2,
//       lazyEquity: 4.3,
//       cashflowSheet: 4.2,
//       ultimatePropertyPlanner: 4.4,
//       freedomCriteria: 4.2,
//       recapGetMemberDecision: 4.3,
//       securePropertySessionTime: 4.4,
//       askForReferral: 4.2,
//       expectationDecision: 4.4,
//       finalRecap: 4.2,
//       avgDuration: '58 mins',
//       overallScore: 4.25
//     },
//     // Additional 5 sessions to make total of 7
//     ...[
//       'David Miller',
//       'Laura Johnson',
//       'Peter Parker',
//       'Mary Jane',
//       'Tony Stark'
//     ].map(name => ({
//       strategist: name,
//       strategistEmail: `${name.toLowerCase().replace(' ', '.')}@example.com`,
//       rapportBuild: randomScore(),
//       qualifyingAppointment: randomScore(),
//       setToneEarly: randomScore(),
//       setAgenda: randomScore(),
//       unpackDiscoveryQuestions: randomScore(),
//       freedomKnowledge: randomScore(),
//       personalStory: randomScore(),
//       financeAssessment1: randomScore(),
//       financeAssessment2: randomScore(),
//       lazyEquity: randomScore(),
//       cashflowSheet: randomScore(),
//       ultimatePropertyPlanner: randomScore(),
//       freedomCriteria: randomScore(),
//       recapGetMemberDecision: randomScore(),
//       securePropertySessionTime: randomScore(),
//       askForReferral: randomScore(),
//       expectationDecision: randomScore(),
//       finalRecap: randomScore(),
//       avgDuration: randomDuration(),
//       overallScore: randomScore()
//     }))
//   ];

const SessionSummary = ()=>{
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [discoverySessions,setDiscoverySessions] = useState([])
  const [strategistSessions,setStrategistSessions] = useState([])
  function formatDate(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  }
  
     
  useEffect(()=>{
    const formattedStartDate = fromDate !="" ? formatDate(fromDate) : "";
    const formattedEndDate = toDate !="" ? formatDate(toDate) : "";
    const disdata = async()=>{ 
      
      const getdisData = await getSummaryAnalysis(formattedStartDate,formattedEndDate,"discovery")
      setDiscoverySessions(getdisData)
    }
    const strategistdata = async() => {
      const getStrategistData = await getSummaryAnalysis(formattedStartDate,formattedEndDate,"strategy")
      setStrategistSessions(getStrategistData)
    }
    strategistdata()
    disdata()

  },[fromDate,toDate])

console.log(discoverySessions,strategistSessions)
    return(
      <div style={{"padding":"1.5rem"}}>
        <div style={{fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' ,display: 'flex',
  flexDirection: 'column',
  gap: '24px'}}>
                 <SummaryDateRangeFilter fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={setFromDate}
        onToDateChange={setToDate} />
                 <KeyMetrics metrics={performanceMetrics} />
                 
          <SessionViews discoverySessions={discoverySessions}
        strategistSessions={strategistSessions}/>

        {/* <SessionTables discoverySessions={discoverySessions}
        strategistSessions={strategistSessions}/> */}
        <PerformanceTrend trend={performanceMetrics.monthlyTrend} />
        <PerformanceDetails
        criteriaBreakdown={performanceMetrics.criteriaBreakdown}
        topPerformers={performanceMetrics.topPerformers}
      />
     </div>
     </div>
    )
}


export default SessionSummary