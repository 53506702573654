import React, { useState } from "react";
import Logout from "../Logout";
import aisms from "../../assets/aisms.png";

import Two from "../../assets/2.svg";
import Six from "../../assets/6.svg";
import Recycle from "../../assets/recycle.svg";
import Settings from "../../assets/settings.svg";
import Lead from "../../assets/lead.svg";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo_white_freedom.png";
import Zoom from "../../assets/zoom.svg";
import "./SidebarDesktop.css";
import "../ui-components/icnImage.css";
import SessionList from "../../assets/sessionlist.svg"
import SummaryAnalysis from "../../assets/summaryanalysis.svg"

const SidebarDesktop = ({ screenWidth }) => {
    const [showAISMSSubmenu, setShowAISMSSubmenu] = useState(false);
    const [showZoomSubmenu,setShowZoomSubmenu] = useState(false)
    const toggleAISMSSubmenu = () => {
        setShowAISMSSubmenu(!showAISMSSubmenu);
    };
    const toggleZoomSubmenu = ()=>{
        setShowZoomSubmenu(!showZoomSubmenu)
    }
    return (
        <div
            className="side-menu"
            style={{ display: screenWidth < 630 && "none" }}
        >
            <div className="top-holder">
                <center>
                    <img src={Logo} alt="Logo" className="logo" />
                </center>
            </div>
            <div className="middle-holder">
                {/* AI SMS Main Menu */}
                <span onClick={toggleAISMSSubmenu} className="menu-item aisms"  style={{fontSize:14}}>
                <img src={aisms} alt="AiSms" className="icn" />

                    AI SMS
                    <span className={`arrow ${showAISMSSubmenu ? 'up' : 'down'}`}>  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 2 L8 6 L3 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</span>

                </span>
                {/* AI SMS Submenu */}
                {showAISMSSubmenu && (
                    <div className="submenu ms-3">
                        <span>
                            <Link to="./"  style={{fontSize:14}}>
                                <img src={Two} alt="Analysis" className="icn" />
                                Analysis
                            </Link>
                        </span>
                        <span>
                            <Link to="./users"  style={{fontSize:14}}>
                                <img src={Six} alt="Contacts" className="icn" />
                                Contacts
                            </Link>
                        </span>
                        <span>
                            <Link to="./inbox"  style={{fontSize:14}}>
                                <img src={Recycle} alt="Inbox" className="icn" />
                                Inbox
                            </Link>
                        </span>
                        <span>
                            <Link to="./leads"  style={{fontSize:14}}>
                                <img src={Lead} alt="Leads" className="icn" />
                                Leads
                            </Link>
                        </span>
                    </div>
                )}
                {/* AI SMS Main Menu */}
                <span onClick={toggleZoomSubmenu} className="menu-item aisms"  style={{fontSize:14}}>
                <img src={Zoom} alt="Zoom Analysis" className="icn" />

                Zoom Analysis
                    <span className={`arrow ${showZoomSubmenu ? 'up' : 'down'}`}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 2 L8 6 L3 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg></span>

                </span>
                {/* AI SMS Submenu */}
                {showZoomSubmenu && (
                    <div className="submenu ms-3">
                {/* Other Menu Items */}
                <span>
                    <Link to="./zoom-analysis" style={{fontSize:14}}>
                        <img src={SessionList} alt="Session List" className="icn" />
                        Session Analysis
                    </Link>
                    <Link to="./sessionsummary"  style={{fontSize:14}}>
                        <img src={SummaryAnalysis} alt="Summary Analysis" className="icn" />
                        Summary Analysis
                    </Link>
                </span>
                </div>
                )}
                <span>
                    <Link to="./settings"  style={{fontSize:14}}>
                        <img src={Settings} alt="Settings" className="icn" />
                        Settings
                    </Link>
                </span>
            </div>

            <Logout />
        </div>
    );
};

export default SidebarDesktop;
